html, body {
    background-color: #212121;
    font-family: Roboto;
}

.siteContent {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.mainContent {
    flex: 1;
    background-color: #f5f5f5;
}

.logotext {
    color: #f5f5f5;
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    text-align: left;
    letter-spacing: 2px;
    margin-left: 7px;
    font-size: 20px;
}

#footerLogoText {
    font-size: 16px !important;
}

#navigationBar {
    background-color: #212121;
}

img#logo {
    height: 52px;
    max-height: 52px;
}

.error-text {
    color: #212121;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}

.MuiButton-containedPrimary {
    color: #fff !important;
    background-color: rgb(1, 87, 155) !important;
}

.MuiCircularProgress-colorPrimary {
    color: #000 !important;
}

.productPurchaseFeedItem {
    height: 184px;
    width: 90%;
    max-width: 400px;
    background: gray;
    margin:auto; 
    overflow:hidden;
    border-radius:10px; 
    box-shadow: 0px 1px 12px rgba(33, 33, 33, 0.5);
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;
    display: block;
}

.productPurchaseFeedItem:hover {
    filter: brightness(150%);
}

.productPurchaseFeatureItem {
    width: 90%;
    max-width: 400px;
    background: #f5f5f5;
    margin:auto;
    overflow:hidden;
    border-radius:10px;
    box-shadow: 0px 1px 12px rgba(33, 33, 33, 0.5);
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;
    display: block;
}

.productPurchaseFeatureItem:hover {
    filter: brightness(128%);
}

.productName {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3em;
    background: rgba(33, 33, 33, 0.5);
}

.productNameText {
    font-size: 1.5em;
    color: #f5f5f5;
    font-weight: 700;
    text-align: left;
    margin-left: 0.5em;
    margin-top: 0.25em;
}

.productFeatureTitle {
    display: flex;
    flex-direction: row;
}

.productFeatureBadge {
    margin-left: 0.5em;
    margin-top: 0.5em;
}

.productFeatureNameText {
    font-size: 1.5em;
    color: #212121;
    font-weight: 700;
    text-align: left;
    margin-left: 0.5em;
    margin-top: 0.25em;
}

.productFeatureDescriptionText {
    font-size: 0.95em;
    color: #212121;
    font-weight: 400;
    text-align: left;
    margin-left: 0.85em;
    margin-right: 0.85em;
    margin-top: 0.25em;
    margin-bottom: 0.85em;
}

.productFeatureDisclosureLink {
    font-size: 1.1em;
    color: rgb(1, 87, 155);
    font-weight: 700;
    text-align: left;
    margin-left: 0.75em;
    margin-right: 0.85em;
    margin-bottom: 0.85em;
}

.menuItem {
    color: #f5f5f5;
    user-select: none;
}

#navbarMenu.is-active {
    background-color: #212121;
}

#navbarMenu .navbar-item:hover {
    background-color: #424242;
}

#burgerDiscloseButton {
    margin-top: 8px;
    color: #f5f5f5;
}

#burgerDiscloseButton:hover {
    background-color: #424242;
}

@media only screen and (max-width: 400px) {

    .logotext {
        color: #f5f5f5;
        font-family: Montserrat, sans-serif;
        font-weight: 800;
        text-align: left;
        letter-spacing: 2px;
        margin-left: 7px;
        font-size: 16px;
    }

    #footerLogoText {
        font-size: 16px !important;
    }

    img#logo {
        height: 48px;
        max-height: 48px;
    }

    .productNameText {
        font-size: 1.1em;
        color: #f5f5f5;
        font-weight: 700;
        text-align: left;
        margin-left: 0.5em;
        margin-top: 0.65em;
    }

    .productFeatureNameText {
        font-size: 1.1em;
        color: #212121;
        font-weight: 700;
        text-align: left;
        margin-left: 0.5em;
        margin-top: 0.65em;
    }

    .productFeatureDescriptionText {
        font-size: 0.75em;
        color: #212121;
        font-weight: 400;
        text-align: left;
        margin-left: 0.85em;
        margin-right: 0.85em;
        margin-top: 0.25em;
        margin-bottom: 0.85em;
    }

    .productFeatureDisclosureLink {
        font-size: 0.85em;
        color: rgb(1, 87, 155);
        font-weight: 700;
        text-align: left;
        margin-left: 0.75em;
        margin-right: 0.85em;
        margin-bottom: 0.85em;
    }
}

.feed {
    animation: feedFadein 0.82s;
}

@keyframes feedFadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#feedSection {
    padding-top: 24px !important;
}

#feedContainer {
    max-width: 800px !important;
    margin: auto !important;
}

.feedSectionTitle {
    font-size: 2.3em;
    font-weight: 800;
    color: #212121;
    width: 90%;
    max-width: 400px;
    margin:auto;
    text-align: left;
}

#productImageSection {
    padding: 0px !important;
    background-color: #e0e0e0;
}

#productPurchaseSection {
    padding: 0px !important;
}

#productDescriptionSection {
    padding: 0px !important;
    background-color: #e0e0e0;
}

#productPriceSection {
    padding: 0px !important;
    background-color: #e0e0e0;
}

#productPriceContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    position: relative;
    background-color: #212121;
}

#freeShippingText {
    background: #2E7D32;
    background: -webkit-linear-gradient(to right, #2E7D32, #4CAF50);
    background: linear-gradient(to right, #2E7D32, #4CAF50);
    border-radius: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: 0.75em;
}

#checkoutSection {
    padding: 0px !important;
}

.checkoutContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    position: relative;
}

#checkoutContent {
    margin-top: 24px;
    margin-bottom: 12px;
    margin-left: 10px;
    padding-left: 2.5em;
}

#proceedToCheckoutButton {
    width: 240px;
}

.productPriceText {
    font-weight: 800;
    margin-left: 12px;
    margin-bottom: 8px;
    color: #f5f5f5;
}

.productPurchaseImageContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    background-color: #616161;
    overflow: hidden;
    position: relative;
}

.productFeatureContainerImageHeight {
    min-height: 250px !important;
}

.productPurchaseImageContainerHeight {
    height: 200px;
}

.productPurchaseImageContainerHeight.collapsed {
    height: 100px;
}

.productPurchaseImageContainerHeight.framed {
    height: 300px !important;
}

.productLoading {
    margin-top: 32px;
}

.customizeSynthGrassCutsContainer .MuiChip-root {
    background-color: rgba(1, 87, 155, 1);
    color: #f5f5f5;
    font-weight: 700;
}

.customizeSynthGrassCutsContainer .MuiChip-root:hover {
    background-color: rgba(1, 87, 155, 1);
    filter: brightness(90%);
}

.customizeSynthGrassCutsContainer .MuiChip-root:focus {
    background-color: rgba(1, 87, 155, 1);
}

.customizeSynthGrassCutsContainer .MuiSvgIcon-root {
    color: #f5f5f5;
}

#synthGrassCutsAddButton {
    vertical-align: bottom;
    margin-left: 12px;
    margin-top: 24px;
}

#synthGrassCutInputContainer {
    display: flex;
    flex-direction: row;
}

.synthGrassCut {
    margin-top: 4px;
}

.fullSizeProductImage {
    position: relative;
    max-width: 100%;
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.productPurchaseFeatureItem .fullSizeProductImage {
    margin-top: 8px;
}

#productImageLoading {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    top: 40%;
    z-index: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

#productDescriptionContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    position: relative;
    background-color: #212121;
}

.productDescriptionText {
    font-weight: 800;
    margin-left: 12px;
    color: #f5f5f5;
}

.productDescriptionTextSize {
    font-size: 1.23em;
}

.productDeliveryDescription {
    margin-left: 12px;
    margin-top: 8px;
    font-size: 0.9em;
}

#productMetadataSection {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

#productMetadataContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    position: relative;
}

.contactPopoverContent a {
    margin-left: 12px;
    font-size: 1.13em;
    margin-right: 12px;
    color: #212121;
    font-weight: 300;
    width: 100%;
}

.contactPopoverElement {
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    width: 100%;
}

.contactPopoverElement:first-of-type {
    border-style: solid;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-color: #e0e0e0;
}

.contactPopoverElement:hover {
    background-color: #e0e0e0;
}

.productMetadataHeader {
    font-weight: 800;
    font-size: 1.23em;
}

.productMetadataDescription {
    margin-top: 0.5em;
    font-size: 1.1em;
}

.productMetadataChipsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4px;
}

.metadataChip {
    font-weight: 600;
    border-style: solid;
    border-width: 1px;
    border-color: #212121;
    border-radius: 9px;
    margin-right: 6px;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 8px;
}

.productMetadataChipsContainer .MuiChip-root {
    margin-top: 8px;
    margin-right: 6px;
}

.productPurchaseStepContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    position: relative;
    font-size: 1.23em;
}

.productPurchaseStepHeader {
    margin-top: 0.75em;
}

.serviceDescription {
    margin-top: 1em;
}

.productPurchaseStepNumber {
    width: 1.23em;
    height: 1.23em;
    background: rgb(230, 81, 0); 
    border-radius: 0.615em;
    font-weight: 800;
    font-size: 1.23em;
    display: inline-block;
    color: #f5f5f5;
    text-align: center;
    margin-left: 10px;
    margin-right: 0.5em;
    line-height: 1.3em;
}

.productPurchaseStepNumber.completedStep {
    background: #707070;
}

.productPurchaseStepDescription {
    font-size: 1.23em;
    font-weight: 800;
    display: inline-block;
    color: #212121;
    padding-top: 0.2em;
}

.productPurchaseStepContent {
    margin-left: 10px;
    padding-left: 2.1em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}

.checkoutLoading {
    margin-top: 32px;
}

.checkoutFormContainer {
    width: 100% !important;
    max-width: 600px !important;
    margin: auto !important;
    position: relative;
    font-size: 1.23em;
}

.checkoutInvoiceContainer {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #e0e0e0;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(33, 33, 33, 0.5);
}

.checkoutInvoice {
    margin-bottom: 12px;
}

.checkoutFormContainer h1 {
    color: #212121;
    font-size: 1.3em;
    margin-top: 12px;
    font-weight: 700;
}

.checkoutFormContainer .line {
    height: 1px;
    width: 95%;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: #212121;
    display: inline-block;
}

.checkoutFormContainer .form {
    display: inline-block;
    width: 95%;
    font-size: 0.9em;
    font-weight: 600;
    color: #424242;
    text-align: left;
}

.checkoutFormContainer .form .row {
    margin-top: 0.5em;
}

.checkoutInvoicePricedItemsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.checkoutInvoiceAddonItem {
    margin-top: 8px;
}

.checkoutInvoicePricedItemsContainer .lineItem {
    font-weight: 400;
    width: 50%;
}

.checkoutInvoicePricedItemsContainer .subitem {
    margin-left: 16px;
}

.checkoutInvoicePricedItemsContainer .price {
    text-align: right;
}

.checkoutInvoicePricedItemsContainer .total {
    font-weight: 700;
}

#checkoutPaymentSection {
    padding-top: 0;
}

.checkoutPaymentForm .cardInput {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background-color: #f5f5f5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
}

#placeOrderButton {
    margin-top: 12px;
    width: 100%;
}

.termsContainer {
    color: #424242;
    margin-top: 12px;
    font-weight: 500;   
}

.termsContainer .termsLink {
    text-decoration: underline;
    cursor: pointer;
}

.termsLink:hover {
    filter: brightness(150%);
}

.confirmationLoading {
    margin-top: 32px;
}

.confirmationFormContainer {
    width: 100% !important;
    max-width: 600px !important;
    margin: auto !important;
    position: relative;
    font-size: 1.23em;
}

.confirmationInvoiceContainer {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #e0e0e0;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(33, 33, 33, 0.5);
    padding-bottom: 12px;
}

.confirmationInvoiceContainer h1 {
    color: #212121;
    font-size: 1.3em;
    margin-top: 12px;
    font-weight: 700;
}

#confirmationHeader .MuiSvgIcon-root {
    width: 80px;
    height: 80px;
    background-color: green;
    border-radius: 50%;
    color: #f5f5f5;
}

#confirmationIcon {
    margin-top: 12px;
}

#confirmationSection {
    padding-top: 12px;
}

.confirmationInvoiceContainer .header {
    margin-left: 12px;
}

.confirmationInvoiceContainer h2 {
    color: #212121;
    font-weight: 600;
}

.confirmationIconHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 12px;
}

.confirmationIconHeader .MuiSvgIcon-root {
    display: inline-block;
    margin-left: 12px;
    width: 24px;
    height: 24px;
}

.confirmationIconHeader .header {
    display: inline-block;
    line-height: 24px;
}

.confirmationContent {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.confirmationContentSpacer {
    width: 48px;
}

.footerHero {
    background: url("/assets/images/footer_hero.jpeg") no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
}

#footerSection {
    padding-top: 0;
    background-color: #212121;
}

#contactContainer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;
}

.contactText {
    color: #f5f5f5 !important;
    padding-left: 4px;
    padding-right: 4px;
}

#purchaseAddonsSection {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

#purchaseAddonsContainer {
    width: 100% !important;
    max-width: 800px !important;
    margin: auto !important;
    position: relative;
}

#purchaseAddonsContainer h1 {
    color: #212121;
    font-size: 1.75em;
    font-weight: 700;
}

#purchaseAddonsContainer h2 {
    color: #212121;
    font-size: 1.3em;
    font-weight: 400;
}

.purchaseAddonsLoading {
    margin-top: 32px;
}

.purchaseAddonContainer {
    margin-top: 16px;
}

.purchaseAddonItemContainer {
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 12px rgba(200, 200, 200, 0.5);
}

.purchaseAddonImage {
    min-width: 120px;
    align-items: center;
    display: flex;
    justify-content: left;
}

.purchaseAddonContent {
    margin-left: 16px;
}

.purchaseAddonUnitPrice {
    color: #338a3e;
    font-weight: 800;
}

.purchaseAddonUnits {
    color: #888888;
    font-variant: small-caps;
    letter-spacing: 1px;
}

.purchaseAddonSelector {
    display: inline-block;
    margin-top: 8px;
}

.purchaseAddonPlus {
    font-weight: 800;
    font-size: 1.3em;
    padding-left: 8px;
    padding-right: 8px;
    color: #f5f5f5;
    background: rgba(1, 87, 155, 1);
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.purchaseAddonPlus:hover {
    filter: brightness(125%);
}

.purchaseAddonCount {
    font-weight: 800;
    font-size: 1.3em;
    margin-left: 8px;
    margin-right: 8px;
    display: inline-block;
    overflow: hidden;
    user-select: none;
}

.purchaseAddonMinus {
    font-weight: 800;
    font-size: 1.3em;
    padding-left: 8px;
    padding-right: 8px;
    color: #f5f5f5;
    background: rgba(1, 87, 155, 1);
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.purchaseAddonMinus:hover {
    filter: brightness(125%);
}

.purchaseAddonsCheckout {
    font-weight: 800;
    font-size: 1.4em;
    cursor: pointer;
    color: rgba(1, 87, 155, 1);
    margin-top: 8px;
}

.purchaseAddonsCheckout:hover {
    filter: brightness(125%);
}

@media only screen and (max-width: 400px) {
    #checkoutInvoiceSection {
        padding-left: 8px;
        padding-right: 8px;
    }

    #checkoutPaymentSection {
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media only screen and (min-width: 500px) {

    .productDescriptionTextSize {
        font-size: 1.5em;
    }

    #productPurchaseImageContainer {
        width: 100% !important;
        max-width: 800px !important;
        margin: auto !important;
        height: 220px !important;
        background-color: #616161;
        overflow: hidden;
        position: relative;
    }
}

@media only screen and (min-width: 600px) {
    .productDescriptionTextSize {
        font-size: 1.65em;
    }

    .productPurchaseImageContainerHeight {
        height: 240px;
    }
}

@media only screen and (min-width: 800px) {
    .productDescriptionTextSize {
        font-size: 1.75em;
    }

    .productPurchaseImageContainerHeight {
        height: 280px;
    }
}

@media only screen and (min-width: 1200px) {
    #feedContainer {
        max-width: 1000px !important;
        margin: auto !important;
    }

    .feedSectionTitle {
        font-size: 2.3em;
        font-weight: 800;
        color: #212121;
        width: 90%;
        max-width: 600px;
        margin:auto;
        text-align: left;
    }

    .logotext {
        color: #f5f5f5;
        font-family: Montserrat, sans-serif;
        font-weight: 800;
        text-align: left;
        letter-spacing: 2px;
        margin-left: 5px;
        font-size: 24px;
    }

    #footerLogoText {
        font-size: 16px !important;
    }

    img#logo {
        height: 64px;
        max-height: 64px;
    }

    .productPurchaseFeedItem {
        width: 90%;
        max-width: 440px;
        background: gray;
        margin:auto;
        overflow:hidden;
        border-radius:10px;
        box-shadow: 0px 1px 12px rgba(33, 33, 33, 0.5);
        margin-bottom: 16px;
        cursor: pointer;
        position: relative;
        display: block;
    }

    .productPurchaseFeatureItem {
        width: 90%;
        max-width: 440px;
        background: #f5f5f5;
        margin:auto;
        overflow:hidden;
        border-radius:10px;
        box-shadow: 0px 1px 12px rgba(33, 33, 33, 0.5);
        margin-bottom: 16px;
        cursor: pointer;
        position: relative;
        display: block;
    }

    .productPurchaseImageContainerHeight {
        height: 320px;
    }

    .productDescriptionTextSize {
        font-size: 1.85em;
    }
}

#chooseLocationDialog .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mapContainer {
    flex: auto;
    background-color: #e0e0e0;
    overflow: hidden;
    height: 100%;
    position: relative;
}

#map {
    width: 100%;
    height: 100%;
}

.MuiToolbar-root .MuiIconButton-edgeEnd {
    margin-left: auto;
}

.locationSuggestions {
    position: absolute;
    top: 0;
    z-index: 2000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    background-color: #f5f5f5;
    width: 100%;
    border-width: 1px;
    border-color: #909090;
    border-style: solid;
    opacity: 0.9;
}

.locationSuggestionColumn {
    max-width: 90%;
}

.locationIconColumn {
    max-width: 5%;
}

.locationSuggestion {
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0.4em;
}

.locationSuggestion:hover {
    background-color: #e0e0e0;
}

.locationSuggestionIcon {
    vertical-align: top;
}

.locationSuggestionText {
    vertical-align: top;
    line-height: 1.6em;
}

.fetchingAddressProgressOverlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    background-color: #212121;
    opacity: 0.25;
}

.fetchingAddressProgressElement {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.fetchingAddressProgressElement .MuiCircularProgress-root {
    position: absolute;
    top: 48%;
}

.chooseLocationContainer {
    position: absolute;
    bottom: 20%;
    width: 100%;
    pointer-events: none;
}

.chooseLocationContainer .MuiButton-containedPrimary {
    pointer-events: all;
}

.quantityInputContainer {
    display: flex;
    flex-direction: row;
    margin-top: -8px;
}

.quantityForm .MuiInput-formControl {
    min-width: 80px;
}

.unitsForm {
    margin-left: 12px;
}

.unitsForm .MuiInput-formControl {
    min-width: 80px;
}

#chooseQuantityButton {
    margin-top: 12px;
}

.deliveryTimeInputContainer {
    display: flex;
    flex-direction: row;
    margin-top: -8px;
}

.dateForm .MuiInput-formControl {
    min-width: 80px;
}

.timeForm {
    margin-left: 12px;
}

.timeForm .MuiInput-formControl {
    min-width: 80px;
}

#chooseDeliveryTimeButton {
    margin-top: 12px;
}

#siteContactInputContainer {
    margin-left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -16px;
}

.nameInput {
    margin-right: 16px;
}

.nameInput .MuiTextField-root {
    margin-bottom: 0;
}

.phoneInput {
    margin-top: 8px;
    margin-bottom: 8px;
}

@media only screen and (max-width: 1150px) {
    .productFeatureContainerImageHeight {
        min-height: 200px !important;
    }
}

@media only screen and (max-width: 600px) {
    #contactContainer {
        display: block;
    }

    .productPurchaseImageContainerHeight.framed {
        height: 300px !important;
    }
}

@media only screen and (max-width: 500px) {
    .productPurchaseImageContainerHeight.framed {
        height: 240px !important;
    }

    .productFeatureContainerImageHeight {
        min-height: 200px !important;
    }

    #purchaseAddonsSection {
        padding-left: 8px;
        padding-right: 8px;
    }

    #purchaseAddonsContainer h2 {
        font-size: 1em;
    }
}

@media only screen and (max-width: 400px) {
    .productPurchaseImageContainerHeight.framed {
        height: 220px !important;
    }

    .productFeatureContainerImageHeight {
        min-height: 160px !important;
    }
}

@media only screen and (max-width: 350px) {
    .productPurchaseImageContainerHeight.framed {
        height: 180px !important;
    }

    .productFeatureContainerImageHeight {
        min-height: 140px !important;
    }

    #purchaseAddonsContainer h1 {
        font-size: 1.3em;
    }

    #purchaseAddonsContainer h2 {
        font-size: 0.75em;
    }
}